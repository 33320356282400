.wrapper {
  max-width: 710px;
  padding: 0 var(--margin-md);
  margin: 0 auto !important;
}

.card {
  background-color: var(--kale-1);
  padding: var(--tfd-spacing-lg) var(--tfd-spacing-md);
  text-align: center;
}

.lottie {
  width: 190px;
  margin: 0 auto;
}

.button {
  width: 100%;
  min-width: unset !important;
}

.desktop {
  display: none;
}

@media (--viewport-lg) {
  .wrapper {
    padding: 0;
    max-width: 840px;
  }

  .card {
    margin: 0 auto;
    max-width: 840px;
  }

  .lottie {
    width: 245px;
  }

  .desktop {
    display: block;
  }
}
